import React, { Component } from 'react'
import Link from 'next/link'
import debounce from 'lodash/debounce'
import { InView } from 'react-intersection-observer'
import { FaPooStorm, FaPlug, FaGamepad } from 'react-icons/fa'

import { log } from '../../util'

const getGlobeSize = () => {
  let min = 625
  let max = 625
  const innerWidth = typeof window === 'undefined' ? max : window.innerWidth
  const innerHeight = typeof window === 'undefined' ? max : window.innerHeight

  if (innerWidth < 1300 && innerWidth > 800) {
    min = 500
    max = 500
  }
  const width = Math.min(innerWidth, Math.max(min, Math.min(innerWidth - 200, max)))
  const height = Math.min(innerHeight, Math.max(min, Math.min(width, Math.min(innerHeight, max))))
  return { width, height }
}

export default class HomeHosting extends Component {
  state = {
    currentStep: this.props.currentStep || 1,
    visible: false,
  }

  ringDirection = 1
  started = false

  componentDidMount = () => {
    if (!this.started && this.state.visible) this.setup()

    window.addEventListener('scroll', this.scrollFunc)
  }

  componentDidUpdate() {
    if (this.props.currentStep && this.state.currentStep !== this.props.currentStep) {
      this.setState({ currentStep: this.props.currentStep })
    }
    if (!this.started && this.state.visible) this.setup()
  }

  componentWillUnmount = () => {
    if (this.autoStep) clearInterval(this.autoStep)
    window.removeEventListener('scroll', this.scrollFunc)
  }

  scrollFunc = () => {
    this.setup()
  }

  setStep(currentStep) {
    if (currentStep > 3) currentStep = 1
    if (this.props.setStep) this.props.setStep(currentStep)
    else this.setState({ currentStep })

    if (currentStep === 1) {
      if (this.billingAnimation) this.billingAnimation.visible = false
      this.normalLighting()
    } else if (currentStep === 2) {
      if (this.billingAnimation) this.billingAnimation.visible = false
      this.darkLighting()
    } else if (currentStep === 3) {
      if (this.billingAnimation) this.billingAnimation.visible = true
      this.normalLighting()
    }
  }

  setup = async () => {
    if (this.started) return
    this.started = true
    const THREE = await import('three')
    if (this.autoStep) clearInterval(this.autoStep)

    this.autoStep = setInterval(() => {
      if (!this.state.visible) return
      this.setStep(this.state.currentStep + 1)
    }, 5000)

    this.camera = new THREE.PerspectiveCamera(45, 1, 1, 185)
    this.camera.position.set(0, 40, 40)
    this.camera.lookAt(0, 0, 0)

    this.scene = new THREE.Scene()

    const { OBJLoader } = await import('three/examples/jsm/loaders/OBJLoader')
    const { MTLLoader } = await import('three/examples/jsm/loaders/MTLLoader')
    const { OrbitControls } = await import('three/examples/jsm/controls/OrbitControls')

    let controls
    if (window.innerWidth > 800) {
      try {
        controls = new OrbitControls(this.camera, this.globeMount)
        controls.target.set(0, 0, 0)
        controls.update()
        controls.rotateSpeed = 0.4
        controls.enablePan = false
        controls.enableZoom = false
      } catch (err) {
        log.error('Unable to setup THREE OrbitControls', err.message)
      }
    }

    const objLoader = new OBJLoader()
    const mtlLoader = new MTLLoader()

    function setGlobeScale(globe) {
      // if (window.innerWidth <= 800) {
      //   globe.scale.set(0.37, 0.37, 0.37)
      // } else {
      //   globe.scale.set(0.37, 0.37, 0.37)
      // }
    }

    mtlLoader.load('/obj/lpEarth.mtl', mtl => {
      mtl.preload()
      objLoader.setMaterials(mtl)
      objLoader.load('/obj/lpEarth.obj', object => {
        this.globe = new THREE.Group()

        // Common modifications
        object.children[0].material.flatShading = true
        object.children[1].material.flatShading = true
        object.children[0].position.set(0, -60, 0)
        object.children[1].position.set(0, -60, 0)
        object.scale.set(0.37, 0.37, 0.37)
        setGlobeScale(object)
        object.position.set(0, 0.5, 0)

        // modelWorld is the full lpEarth.obj with texture
        this.modelWorld = object
        this.globe.add(this.modelWorld)

        // billingWorld
        object.traverse(node => {
          if (node.isMesh) node.material = node.material.clone()
          if (node.name === 'flatEarth_sea') {
            const wireframeGeometry = new THREE.WireframeGeometry(node.geometry)
            const wireframeMaterial = new THREE.LineDashedMaterial({
              color: 0xffffff,
              dashSize: 1,
              gapSize: 3,
            })
            this.billingAnimation = new THREE.LineSegments(wireframeGeometry, wireframeMaterial)
            this.billingAnimation.computeLineDistances()
            this.billingAnimation.name = 'wireframe'
            this.billingAnimation.scale.set(0.39, 0.39, 0.39)
            this.billingAnimation.position.set(0, -22.5, 0)
            this.billingAnimation.visible = this.state.currentStep === 3
            this.globe.add(this.billingAnimation)
          }
        })

        // const ringSize = 10
        // const geometry = new THREE.RingGeometry(ringSize - 0.2, ringSize, 32, 1, 0, 7)
        // const material = new THREE.MeshToonMaterial({
        //   color: 0x00ccff,
        //   emissive: 0x00ccff,
        //   side: THREE.DoubleSide,
        // })
        // this.initialRing = new THREE.Mesh(geometry, material)
        // this.initialRing.position.set(14.5, 14.5, 0)
        // this.initialRing.rotation.x = Math.PI / 2
        // this.initialRing.lookAt(0, 0, 0)
        // this.globe.add(this.initialRing)

        // this.step2Ring = new THREE.Mesh(geometry, material)
        // this.step2Ring.position.set(-15, 12, 1)
        // this.step2Ring.rotation.x = Math.PI / 2
        // this.step2Ring.lookAt(0, 0, 0)
        // this.globe.add(this.step2Ring)

        this.scene.add(this.globe)
      })
    })

    // const axesHelper = new THREE.AxesHelper(5)
    // this.scene.add(axesHelper)

    try {
      this.renderer = new THREE.WebGLRenderer({
        // powerPreference: 'high-performance',
        // antialias: window.devicePixelRatio <= 1,
        alpha: true,
      })
      this.renderer.setAnimationLoop(this.animation)
      this.renderer.setPixelRatio(window.devicePixelRatio)
      this.renderer.shadowMap.enabled = true

      this.ambientLight = new THREE.AmbientLight(0xddd)
      this.scene.add(this.ambientLight)

      const skyColor = 0x1289f6
      const groundColor = 0x004175
      this.hLight = new THREE.HemisphereLight(skyColor, groundColor, 4.5)
      this.scene.add(this.hLight)

      this.sunlight = new THREE.DirectionalLight(0xffffff, 6.5)
      this.sunlight.position.set(-55, -35, 10)
      this.sunlight.lookAt(0, 40, 20)
      this.scene.add(this.sunlight)
      this.sunlight.visible = true

      this.globeMount.appendChild(this.renderer.domElement)

      const resizeGlobe = () => {
        const { width, height } = getGlobeSize()
        this.camera.aspect = width / height
        this.camera.updateProjectionMatrix()
        this.renderer.setSize(width, height)
        if (this.globe) setGlobeScale(this.globe)
      }
      resizeGlobe()

      window.addEventListener('resize', debounce(resizeGlobe, 200))

      this.normalLighting()
    } catch (err) {
      console.error(
        'Unable to create THREEJS context - Possibly an issue with your graphics driver!'
      )
    }
  }

  darkLighting = () => {
    if (this.ambientLight) this.ambientLight.visible = true
    if (this.hLight) this.hLight.visible = false
    if (this.sunlight) {
      this.sunlight.intensity = 6.5
    }
  }

  normalLighting = () => {
    if (this.ambientLight) this.ambientLight.visible = true
    if (this.hLight) this.hLight.visible = true
    if (this.sunlight) {
      this.sunlight.intensity = 4
    }
  }

  animation = time => {
    if (!this.globe || !this.state.visible) return
    this.globe.rotation.z = -(time / 36000)
    this.globe.rotation.y = time / 10000
    this.renderer.render(this.scene, this.camera)

    let animateRing
    if (this.state.currentStep === 1) {
      animateRing = this.initialRing
    }
    if (animateRing) {
      const speed = Math.max(Math.abs(animateRing.scale.x - 1) / 100, 0.001)
      const scale = this.ringDirection ? animateRing.scale.x + speed : animateRing.scale.x - speed
      if (scale > 1) {
        this.ringDirection = 0
      }
      if (scale < 0.1) {
        this.ringDirection = 1
      }
      animateRing.scale.set(scale, scale, 1)
    }
  }

  render() {
    return (
      <div className={`Home-Hosting ${this.state.currentStep === 2 ? 'black' : ''}`}>
        <div className="content">
          <div className="header">
            <h1>Why Self-Host?</h1>
            <h4>
              Own your own data, pay fewer bills &amp; use the internet the way it was intended!
            </h4>
          </div>
          <div className="step-content">
            <div className="steps">
              <div className={`step ${this.state.currentStep === 1 ? 'selected' : ''}`}>
                <button
                  onClick={() => {
                    if (this.autoStep) clearInterval(this.autoStep)
                    this.setStep(1)
                  }}
                >
                  <span className="smaller">1.</span> Own it
                  <div className="smaller">
                    Use your own computer or buy a PiBox.
                    <br />
                    No clouds; it's your hardware and your data.
                  </div>
                </button>
              </div>
              <div className={`step ${this.state.currentStep === 2 ? 'selected' : ''}`}>
                <button
                  onClick={() => {
                    if (this.autoStep) clearInterval(this.autoStep)
                    this.setStep(2)
                  }}
                >
                  <span className="smaller">2.</span> Customize it
                  <div className="smaller">
                    <Link
                      className="example"
                      href="/template/[[...params]]"
                      as="/template/erulabs/photostructure"
                      prefetch={false}
                    >
                      Photostructure
                    </Link>
                    ,{' '}
                    <Link
                      className="example"
                      href="/template/[[...params]]"
                      as="/template/erulabs/ghost"
                      prefetch={false}
                    >
                      Ghost
                    </Link>
                    ,{' '}
                    <Link
                      className="example"
                      href="/template/[[...params]]"
                      as="/template/erulabs/jellyfin"
                      prefetch={false}
                    >
                      Jellyfin
                    </Link>
                    , and so much more.
                    <br />
                    Explore the world of self-hosted apps!
                  </div>
                </button>
              </div>
              <div className={`step ${this.state.currentStep === 3 ? 'selected' : ''}`}>
                <button
                  onClick={() => {
                    if (this.autoStep) clearInterval(this.autoStep)
                    this.setStep(3)
                  }}
                >
                  <span className="smaller">3.</span> Connect it
                  <div className="smaller">
                    Just like hosted apps that cost monthly, you can access your apps from anywhere.
                  </div>
                </button>
              </div>
            </div>
            <InView
              as="div"
              onChange={(inView, entry) => {
                if (inView) {
                  this.setState({ visible: true })
                  if (this.renderer) this.renderer.setAnimationLoop(this.animation)
                } else {
                  this.setState({ visible: false })
                  if (this.renderer) this.renderer.setAnimationLoop(null)
                }
              }}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div className="globe-container">
                <div className="globe" ref={ref => (this.globeMount = ref)} />
              </div>
            </InView>
            <div className="step-text" style={{ minHeight: '350px' }}>
              {this.state.currentStep === 1 ? (
                <div className="step">
                  <div className="step-icon">
                    <FaPlug />
                  </div>
                  <a
                    rel="noreferrer nofollow"
                    target="_blank"
                    title="View Docs"
                    href="https://docs.kubesail.com/#attaching-a-cluster"
                  >
                    KubeSail
                  </a>{' '}
                  works on any kind of computer.
                  <br /> From an old desktop to a cloud server to our{' '}
                  <a
                    rel="noreferrer nofollow"
                    target="_blank"
                    title="PiBox website"
                    href="https://pibox.io/"
                  >
                    custom designed hardware
                  </a>
                  .{' '}
                  <a
                    rel="noreferrer nofollow"
                    target="_blank"
                    title="KubeSail Documentation"
                    href="https://docs.kubesail.com"
                  >
                    Read the docs
                  </a>{' '}
                  to get started!
                </div>
              ) : this.state.currentStep === 2 ? (
                <div className="step">
                  <div className="step-icon">
                    <FaPooStorm />
                  </div>
                  <div>
                    A{' '}
                    <a
                      rel="noreferrer nofollow"
                      target="_blank"
                      title="KubeSail Template Store"
                      href="https://kubesail.com/templates"
                    >
                      large selection
                    </a>{' '}
                    of apps to install. Tools to replace Google Photos or even Netflix, and so much
                    more!
                    <br /> No lock-in, and no black-boxes.
                    <br /> Well, except the{' '}
                    <a
                      rel="noreferrer nofollow"
                      target="_blank"
                      title="PiBox website"
                      href="https://pibox.io/"
                    >
                      PiBox
                    </a>{' '}
                    of course!
                  </div>
                </div>
              ) : this.state.currentStep === 3 ? (
                <div className="step">
                  <div className="step-icon">
                    <FaGamepad />
                  </div>
                  <div>
                    KubeSail's mission is to fix the internet by making self-hosting viable. If
                    you're an experienced hacker or a complete beginner, our tools, docs and{' '}
                    <a
                      rel="noreferrer nofollow"
                      target="_blank"
                      href="https://discord.gg/N3zNdp7jHc"
                    >
                      community
                    </a>{' '}
                    are here to help you get started!
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={`angle-color ${this.state.currentStep === 2 ? 'black' : 'blue'}`}>
          <div></div>
        </div>
      </div>
    )
  }
}
